import React, { useContext, useEffect } from 'react';

import { Tabs } from 'components';
import { AuthContext, AuthModalContext } from 'auth';
import styled from '@emotion/styled';
import Info from './Info';
import BuyProcessContext, { BuyProcessContextWrapper } from './context';
import Finance from './Finance';
import Review from './Review';
import Deadline from './Deadline';
import ValidatePhone from './ValidatePhone';

const tabs: BuyProcessTab[] = [
  { key: 'info', text: '1 Your Information' },
  { key: 'finance', text: '2 Finance' },
  { key: 'deadline', text: '3 Deadline' },
  { key: 'review', text: '4 Review' }
];

const BuyProcessPageWrapper = () => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen, setForceOpen } = useContext(AuthModalContext);
  const {
    infoTabData,
    financeTabData,
    deadlineTabData,
    listingId,
    activeTab,
    setActiveTab,
    showPhoneValidation
  } = useContext(BuyProcessContext);

  useEffect(() => {
    if (!authorized) {
      setAuthModalOpen(true);
      setForceOpen(true);
    }
  }, [authorized, setAuthModalOpen, setForceOpen]);

  if (!listingId) return <>/:listingId is mandatory on the url</>;

  const getTabProps = (tab: BuyProcessTab) => {
    const active = activeTab === tab.key;
    let disabled = false;

    switch (tab.key) {
      case 'finance':
        if (!infoTabData) disabled = true;
        break;
      case 'deadline':
        if (!financeTabData) disabled = true;
        break;
      case 'review':
        if (!infoTabData) disabled = true;
        break;
      default:
        break;
    }

    return { active, disabled };
  };

  const getTabProgress = tab => {
    let completed = false;
    switch (tab.key) {
      case 'info':
        if (infoTabData) completed = true;
        break;
      case 'finance':
        if (financeTabData) completed = true;
        break;
      case 'deadline':
        if (deadlineTabData) completed = true;
        break;
      case 'review':
        if (showPhoneValidation) completed = true;
        break;
      default:
        break;
    }

    return { completed };
  };

  return (
    <StyledBuyProcessWrapper className="process-wrapper">
      <div className="process-tabs-container">
        <StyledTabs
          activeTab={activeTab}
          onSelect={(_e, value) => setActiveTab(value.key)}
          getTabProps={getTabProps as any}
          getTabStatus={getTabProgress}
          items={tabs}
        />
      </div>
      <div className="process-container">
        {showPhoneValidation ? (
          <ValidatePhone />
        ) : (
          <>
            <Info />
            <Finance />
            <Deadline />
            <Review />
          </>
        )}
      </div>
    </StyledBuyProcessWrapper>
  );
};

const BuyProcessPage: React.FC<{ path?: string }> = () => (
  <BuyProcessContextWrapper>
    <BuyProcessPageWrapper />
  </BuyProcessContextWrapper>
);

export default BuyProcessPage;

const StyledBuyProcessWrapper = styled.div`
  .process-tabs-container {
    background: black;
    display: flex;
    justify-content: center;
    padding: 20px 16px 0;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 36px;
  }
  .process-container {
    max-width: 1057px;
    width: 100%;
    padding: 0 16px;
    margin: auto;
  }
  .checkbox-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0 20px;
  }
  .checkbox-wrapper {
    margin: 22px 0;
  }
  .checkbox-container {
    &__title {
      font-size: 12px;
      line-height: 20px;
      padding: 0 0 8px;
      margin: 0 0 16px;
      border-bottom: 1px solid #dadada;
    }
  }
  .checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 8px 0;
  }
  .flex-block {
    display: flex;
    align-items: center;
    &-justify {
      justify-content: space-between;
    }
  }
  .process-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__title {
      font-size: 12px;
      line-height: 20px;
      margin: 0 0 16px;
      font-weight: normal;
    }
    &__input-block {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
      grid-gap: 14px 20px;
      margin: 0 0 14px;
      .process-form__input-block {
        margin: 0;
      }
      &_three-columns {
        grid-template-columns: 2fr 3fr;
      }
      &_short {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__input {
      width: 100%;

      input {
        height: 36px;
      }
    }
    &__radio-block {
      margin: 0 0 14px;
    }
    &__btn {
      min-width: 116px;
      margin: 22px 0 0 auto;
    }
    &__textarea-block {
      margin: 14px 0;
      .textarea-input {
        min-height: 80px;
      }
    }
    &__textarea {
      width: 100%;
    }
  }
  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .process-tabs-container {
      padding: 0;
    }
    .process-form {
      &__input-block {
        grid-template-columns: 1fr;
      }
      &__btn {
        width: 100%;
      }
    }
    .checkbox-container {
      margin: 0 0 24px;
    }
    .checkbox-wrapper {
      margin: 0;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  max-width: 700px;
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;

  .tab-item {
    color: #c4c4c4;
    border-bottom: 4px solid transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 16px;
    &:disabled {
      opacity: 0.5;
    }
    &.active {
      color: white;
      border-bottom: 4px solid white;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-shrink: 0;
    overflow-x: auto;
    padding: 16px 0 0 0;
    display: block;
    white-space: nowrap;
    text-align: center;

    .tab-item {
      display: inline-block;
      flex-shrink: 0;
      padding: 0 0 8px;
      margin: 0 10px !important;
    }
  }
`;
