import React from 'react';

import styled from '@emotion/styled';

const InfoFormContainer = ({ children }) => {
  return <StyledInfoForm className="info-form">{children}</StyledInfoForm>;
};

export default InfoFormContainer;

const StyledInfoForm = styled.div`
  .info-form {
    &__radio-group {
      margin: 0 0 20px;
    }
    &__radio-btn {
      .label {
        white-space: nowrap;
      }
    }
    &__radio-label {
      margin: 8px 0 14px;
      font-size: 10px;
      line-height: 16px;
    }
    &__input {
      width: auto;
    }
    &__input-group {
      margin: 0 0 18px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
    &__btn-group {
      margin: 0 0 16px;
    }
    &__textarea {
      width: 100%;
      .textarea-input {
        min-height: 80px;
      }
    }
    &__submit-group {
      display: flex;
      justify-content: flex-end;
    }
    &__btn-add {
      color: ${props => props.theme.colors.primary};
    }
    &__btn-submit {
      margin: 16px 0 0;
      width: 112px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .info-form {
      &__radio-group {
        display: flex;
      }
      &__radio-group {
        display: block;
      }
      &__input-group {
        margin: 0;
        grid-template-columns: 1fr;
      }
      &__input {
        margin: 0 0 10px;
      }
    }
    .file-input {
      margin: 0 0 10px;
    }
  }
`;
