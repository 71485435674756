import React from 'react';

interface InfoSummaryProps {
  data: { [key: string]: any };
  onEdit: (value: BuyProcessTabKey) => void;
}

const InfoSummary: React.FC<InfoSummaryProps> = ({ data, onEdit }) => {
  return (
    <div className="review-wrapper">
      <button
        className="review-block__edit-btn x"
        onClick={() => onEdit('info')}
        data-cy="review_info_edit">
        Edit
      </button>
      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-title">Information:</h3>
          <p className="review-text" data-cy="review_buyer_name">
            <strong>{data.type === 'buyer' ? 'Buyer' : 'Agent'}: </strong>
            {data.agent_name || data?.buyer_1_name || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_buyer_email">
            <strong>Email: </strong>
            {data?.agent_email || data?.buyer_1_email || 'N/A'}
          </p>
          <p className="review-text" data-cy="review_buyer_phone">
            <strong>Phone: </strong>
            {data?.agent_phone || data?.buyer_1_phone || 'N/A'}
          </p>
          {data.type === 'agent' && (
            <>
              <p className="review-text">
                <strong>Agent brokerage name: </strong>
                {data?.agent_brokerage_name || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Agent licence number: </strong>
                {data?.agent_licence_number || 'N/A'}
              </p>
            </>
          )}
        </div>

        <div className="review-info-block">
          <div className="review-title empty" />
          {data.type === 'agent' && (
            <>
              <p className="review-text">
                <strong>Buyer: </strong>
                {data?.buyer_1_name || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Buyer email: </strong>
                {data?.buyer_1_email || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Buyer phone: </strong>
                {data?.buyer_1_phone || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Buyer visited property: </strong>
                {data?.buyer_visited_property || 'N/A'}
              </p>
            </>
          )}
          {data?.buyer_2_name && (
            <>
              <p className="review-text" data-cy="review_cobuyer_name">
                <strong>Co-Buyer: </strong>
                {data?.buyer_2_name || 'N/A'}
              </p>
              <p className="review-text" data-cy="review_cobuyer_email">
                <strong>Co-Buyer Email: </strong>
                {data?.buyer_2_email || 'N/A'}
              </p>
            </>
          )}
          {data?.company_name && (
            <>
              <p className="review-text" data-cy="review_company_name">
                <strong>Company Name: </strong>
                {data.company_name || 'N/A'}
              </p>
              <p className="review-text" data-cy="review_company_email">
                <strong>Company Email: </strong>
                {data.company_email || 'N/A'}
              </p>
            </>
          )}
        </div>
      </div>
      {data.additional_info && (
        <>
          <hr className="review-block__border" />
          <div className="review-block review-block">
            <div className="review-info-block">
              <h3 className="review-title">Additional Information:</h3>
              <p className="review-text">{data?.additional_info}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InfoSummary;
