import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import pluralize from 'pluralize';
import styled from '@emotion/styled';
import {
  Button,
  CurrencyInput,
  FileInput,
  FileInputACCEPT,
  RadioButton,
  Slider,
  Tooltip,
  Icon
} from 'components';
import { uploadFileToS3 } from 'api/documents';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { formatCurrency } from 'utils/rifmFormatters';
import { calculateDiscount } from 'utils/listing';

const MIN_PRICE = 80;
const MAX_PRICE = 120;

const percentage = (percent: number, total: number, precise?: boolean): number => {
  const result = (percent / 100) * total;
  return precise ? Number(result.toFixed(2)) : Math.round(result);
};

type FileUpload = { url: string; name: string; };

type Range = {
  min: number;
  max: number;
};

interface FinanceFormProps {
  listing: ListingDetails;
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: { [key: string]: any; }) => void;
}

const FinanceForm: React.FC<FinanceFormProps> = ({ listing, onSubmit, defaultValues }) => {
  const { register, control, errors, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      offerAmount: listing.price,
      approvalLetter: [] as FileUpload[],
      proofOfFunds: [] as FileUpload[],
      preapprove: 'yes',
      proof: 'yes',
      paymentMethod: 'financing',
      preapproveByLender: 'yes',
      ...defaultValues
    }
  });
  const { offerAmount, preapprove, paymentMethod, proof } = watch([
    'offerAmount',
    'preapprove',
    'preapproveByLender',
    'paymentMethod',
    'proof'
  ]);

  const offerAmountRange: Range = useMemo(
    () => ({
      min: percentage(MIN_PRICE, listing.price),
      max: percentage(MAX_PRICE, listing.price)
    }),
    [listing.price]
  );

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Select file...';
    return `${length} ${pluralize('file', length)}`;
  };

  const onFileUpload = async (file, inputName, doctype) => {
    try {
      const { url, documentId } = await uploadFileToS3(file, { listingId: listing.id, doctype });
      const files = getValues(inputName);
      setValue(inputName, [...files, { id: documentId, url, name: file.name, size: file.size }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = (fileToremove, inputName) => {
    const files = getValues(inputName).filter(file => file.id !== fileToremove.id);
    setValue(inputName, files);
  };

  return (
    <StyledForm className="finance-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="offset-top" />
      <p>Please choose your preferred method of payment.</p>
      <div className="finance-form__radio-group">
        <RadioButton
          ref={register({ required: true })}
          name="paymentMethod"
          value="cash"
          label="All Cash"
          buttonLike
          data-cy="all-cash_btn"
        />
        <RadioButton
          ref={register({ required: true })}
          name="paymentMethod"
          label="Financing"
          value="financing"
          buttonLike
          defaultChecked
          data-cy="financing_btn"
        />
      </div>
      <hr />
      <div className="finance-form__input-group">
        <div className="finance-form__slider-input">
          <Controller
            control={control}
            name="offerAmount"
            defaultValue=""
            rules={{ required: 'Required' }}
            render={({ value, onChange, onBlur }) => {
              const aboveOrBelow = Math.abs(Math.round((offerAmount / listing.price) * 100 - 100));
              return (
                <>
                  <CurrencyInput
                    label={
                      <>
                        Offer Amount*{' '}
                        <Tooltip
                          trigger={
                            <Icon
                              name="tooltip-trigger"
                              className="tooltip-trigger"
                              width="14"
                              height="14"
                            />
                          }>
                          <p className="tooltip-content">
                            The Offer Amount is the amount of money you're willing to pay for this
                            home. (If you intend to pay with a mortgage, this amount should include
                            both your down payment and mortgage amount.)
                          </p>
                        </Tooltip>
                      </>
                    }
                    placeholder="Enter"
                    value={value}
                    error={errors.offerAmount}
                    onChange={onChange}
                    onBlur={onBlur}
                    data-cy="offer_amount"
                  />
                  <Slider
                    min={offerAmountRange.min}
                    max={offerAmountRange.max}
                    step={1000}
                    value={offerAmount || 0}
                    onChange={onChange}
                  />
                  <div className="finance-form__slider-input__info" data-cy="closing-cashback">
                    Est. back at closing:{' '}
                    <strong className="green">{calculateDiscount(offerAmount) || '$0'}</strong>
                  </div>
                  <div className="finance-form__slider-input__info" data-cy="list-price">
                    List price: <strong>{formatCurrency(String(listing.price))}</strong>
                  </div>
                  {value && (
                    <div className="finance-form__slider-input__info" data-cy="offer-range">
                      Your offer is <strong>{aboveOrBelow}%</strong>{' '}
                      {offerAmount > listing.price ? 'above' : 'below'} list
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
      <hr />
      {paymentMethod === 'financing' && (
        <div className="finance-form__radio-group">
          <div className="label">Are you already preapproved?</div>
          <RadioButton
            ref={register}
            name="preapprove"
            value="yes"
            label="Yes"
            data-cy="preapprove_yes"
          />
          <RadioButton
            ref={register}
            name="preapprove"
            value="no"
            label="No"
            data-cy="preapprove_no"
          />
        </div>
      )}

      {paymentMethod === 'financing' && preapprove === 'no' && (
        <div className="finance-form__radio-group">
          <div className="label">
            Are you already in the process of being preapproved by your lender? If yes, you agree to
            submitting an offer status as "waiting for pre approval".
          </div>
          <RadioButton
            ref={register}
            name="preapproveByLender"
            value="yes"
            label="Yes"
            defaultChecked
            data-cy="lender_approval_yes"
          />
          <RadioButton
            ref={register}
            name="preapproveByLender"
            value="no"
            label="No"
            data-cy="lender_approval_no"
          />
        </div>
      )}

      {paymentMethod === 'financing' && preapprove === 'yes' && (
        <Controller
          control={control}
          name="approvalLetter"
          defaultValue={[]}
          render={({ value, ...controllerProps }) => (
            <FileInput
              label="Upload pre-approval letter"
              placeholder={getFilesLabel(value)}
              onFileUpload={file => onFileUpload(file, 'approvalLetter', 'Pre approval letter')}
              onFileRemove={file => onFileRemove(file, 'approvalLetter')}
              accept={FileInputACCEPT.ALL}
              files={value}
              multiple
              showSpinner
              {...controllerProps}
            />
          )}
        />
      )}

      {paymentMethod === 'cash' && (
        <div className="finance-form__radio-group">
          <div className="label">Do you have proof of funds?</div>
          <RadioButton ref={register} name="proof" value="yes" label="Yes" defaultChecked />
          <RadioButton ref={register} name="proof" value="no" label="No" />
        </div>
      )}
      {paymentMethod === 'cash' && proof === 'yes' && (
        <Controller
          control={control}
          name="proofOfFunds"
          defaultValue={[]}
          render={({ value, ...controllerProps }) => (
            <FileInput
              label="Upload proof of funds"
              placeholder={getFilesLabel(value)}
              onFileUpload={file => onFileUpload(file, 'proofOfFunds', 'Proof of funds')}
              onFileRemove={file => onFileRemove(file, 'proofOfFunds')}
              accept={FileInputACCEPT.ALL}
              files={value}
              multiple
              showSpinner
              {...controllerProps}
            />
          )}
        />
      )}
      <Button className="finance-form__submit-btn" primary data-cy="continue-button">
        Continue
      </Button>
    </StyledForm>
  );
};

export default FinanceForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 0 12px 0;
  }

  .finance-form__input-group {
    display: flex;
    justify-content: space-between;
  }

  .finance-form__input-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
    align-items: center;
    .finance-form__input {
      width: 100%;
    }
    .dropdown__button {
      height: 32px;
    }
  }

  .finance-form__radio-group {
    & > .label {
      margin-bottom: 8px;
    }
    .radiobutton {
      margin-left: 0;
    }
    .radiobutton:last-child {
      margin-left: 0;
    }
  }

  .finance-form__slider-input {
    position: relative;
    .input {
      width: 183px;
      margin-bottom: 20px;
    }

    .slider {
      margin-bottom: 12px;
      .track {
        height: 4px;
        top: 4px;
      }
      .track-0 {
        background: ${props => props.theme.colors.primary};
      }
      .track-1 {
        background: #aab7c9;
      }
    }

    &__info {
      margin: 4px 0;
      color: #9c9c9e;

      strong {
        color: #000000;
        font-weight: 600;

        &.green {
          color: #11c494;
        }
      }
    }
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .trigger {
    float: right;
    z-index: 10;
    circle {
      fill: ${props => props.theme.colors.lightGray};
    }
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }

  .finance-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .finance-form__input-group {
      flex-direction: column;

      .finance-form__slider-input {
        width: 100%;
        margin-bottom: 24px;
        &:last-of-type {
          margin-bottom: 0;
          margin-left: 0 !important;
          .slider {
            margin-bottom: 0;
          }
        }
        .input {
          width: 100%;
        }
        .content {
          width: 320px;
        }
      }
    }
    .finance-form__input-container {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
  }
`;
