import React from 'react';
import { formatCurrency } from 'utils/rifmFormatters';

interface FinanceSummaryProps {
  data: { [key: string]: any };
  onEdit: (value: BuyProcessTabKey) => void;
}

const FinanceSummary: React.FC<FinanceSummaryProps> = ({ data, onEdit }) => {
  return (
    <div className="review-wrapper">
      <div className="header-property-info">
        <button
          className="review-block__edit-btn"
          onClick={() => onEdit('finance')}
          data-cy="review_finance_edit">
          Edit
        </button>
        <div className="review-block">
          <div className="review-info-block">
            <h3 className="review-title">Finance:</h3>
            <p className="review-text" data-cy="review_payment_type">
              <strong>Payment: </strong>
              {data?.paymentMethod || 'N/A'}
            </p>
          </div>
          <div className="review-info-block">
            <div className="review-title empty" />
            <p className="review-text" data-cy="review_finance_price">
              <strong>Offer Amount: </strong>
              {formatCurrency(data.offerAmount) || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceSummary;
