import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import pluralize from 'pluralize';

import { Button, Input, FileInput, FileInputACCEPT, RadioButton, Textarea } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { uploadFileToS3 } from 'api/documents';
import { REGEXP } from 'consts';
import InfoFormContainer from './InfoFormContainer';

interface BuyerInfoFormProps {
  listingId: string;
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: { [key: string]: any; }) => void;
}

const BuyerInfoForm: React.FC<BuyerInfoFormProps> = ({ listingId, defaultValues, onSubmit }) => {
  const [showCobuyerField, setShowCobuyerField] = useState(false);
  const [showCompanyField, setShowCompanyField] = useState(false);
  const { register, errors, control, getValues, setValue, handleSubmit, watch } = useForm<any>({
    defaultValues: {
      ...defaultValues,
      buyer_1_name: defaultValues?.name,
      buyer_1_email: defaultValues?.email,
      buyer_1_phone: defaultValues?.phone
    },
    mode: 'onSubmit'
  });

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Select file...';
    return `${length} ${pluralize('file', length)}`;
  };

  const onFileUpload = async (fileToAdd, inputName, doctype) => {
    try {
      const { url, documentId } = await uploadFileToS3(fileToAdd, { listingId, doctype });
      const files = getValues(inputName);
      setValue(inputName, [
        ...files,
        { id: documentId, url, name: fileToAdd.name, size: fileToAdd.size }
      ]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = (fileToremove, inputName) => {
    const files = getValues(inputName).filter(file => file.id !== fileToremove.id);
    setValue(inputName, files);
  };

  const offerType = watch('offerType');

  return (
    <InfoFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="info-form__title">Choose your offer style below</p>
        <div className="info-form__radio-group">
          <RadioButton
            ref={register}
            name="offerType"
            value="step"
            label="Step by Step"
            defaultChecked
            buttonLike
            className="info-form__radio-btn"
            data-cy="step-by-step_btn"
          />
          <RadioButton
            ref={register}
            name="offerType"
            value="upload"
            label="Upload an offer"
            buttonLike
            className="info-form__radio-btn"
            data-cy="upload-an-offer_btn"
          />
        </div>

        <p className="info-form__title">Your Information</p>
        <div className="info-form__input-group">
          <Input
            className="info-form__input"
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="buyer_1_name"
            defaultValue=""
            error={errors.buyer_1_name}
            label="Your Full Name*"
            placeholder="Enter Name"
            data-cy="buyer_name"
          />
          <Input
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            name="buyer_1_email"
            defaultValue=""
            error={errors.buyer_1_email}
            className="info-form__input"
            label="Email Address*"
            placeholder="Enter Email"
            data-cy="buyer_email"
          />
        </div>

        <div className="info-form__input-group">
          <Controller
            control={control}
            name="buyer_1_phone"
            rules={{
              required: 'Required',
              validate: value => !value.includes('_') || 'Required'
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="info-form__input"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.buyer_1_phone}
                label="Phone Number*"
                placeholder="Enter Phone Number"
                {...controllerProps}
                data-cy="buyer_phone"
              />
            )}
          />
        </div>

        <div className="info-form__btn-group">
          <Button
            simple
            className="info-form__btn-add"
            onClick={e => {
              e.preventDefault();
              setShowCobuyerField(v => !v);
            }}
            data-cy="add_cobuyer">
            {showCobuyerField ? '-' : '+'} Add a co-buyer
          </Button>
        </div>

        {showCobuyerField && (
          <div className="info-form__input-group">
            <Input
              className="info-form__input"
              ref={register({
                pattern: {
                  value: REGEXP.NAME,
                  message: 'Invalid name'
                }
              })}
              name="buyer_2_name"
              defaultValue=""
              error={errors.buyer_2_name}
              label="Co-buyer Full Name"
              placeholder="Enter Name"
              data-cy="cobuyer_name"
            />
            <Input
              ref={register({
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              name="buyer_2_email"
              defaultValue=""
              error={errors.buyer_2_email}
              className="info-form__input"
              label="Co-buyer Email Address"
              placeholder="Enter Email"
              data-cy="cobuyer_email"
            />
          </div>
        )}

        <hr />

        <div className="info-form__btn-group">
          <Button
            className="info-form__btn-add"
            simple
            onClick={e => {
              e.preventDefault();
              setShowCompanyField(v => !v);
            }}
            data-cy="add_company">
            {showCompanyField ? '-' : '+'} Buying as a Company
          </Button>
        </div>

        {showCompanyField && (
          <div className="info-form__input-group">
            <Input
              className="info-form__input"
              ref={register}
              name="company_name"
              defaultValue=""
              error={errors.company_name}
              label="Company Name"
              placeholder="Enter Name"
              data-cy="company_name"
            />
            <Input
              ref={register({
                pattern: {
                  value: REGEXP.EMAIL,
                  message: 'Invalid email address'
                }
              })}
              name="company_email"
              defaultValue=""
              error={errors.company_email}
              className="info-form__input"
              label="Company Email Address"
              placeholder="Enter Email"
              data-cy="company_email"
            />
          </div>
        )}

        {offerType === 'upload' && (
          <>
            <p className="info-form__title">Upload Documents</p>
            <div className="info-form__input-group">
              <Controller
                control={control}
                name="official_offer"
                defaultValue={[]}
                render={controllerProps => (
                  <FileInput
                    label="Official Offer to Buy"
                    placeholder={getFilesLabel(controllerProps.value)}
                    onFileUpload={file => onFileUpload(file, 'official_offer', 'Offer')}
                    onFileRemove={file => onFileRemove(file, 'official_offer')}
                    files={controllerProps.value}
                    accept={FileInputACCEPT.ALL}
                    multiple
                    showSpinner
                    data-cy="upload_offer"
                    {...controllerProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="preapproval_pof"
                defaultValue={[]}
                render={controllerProps => (
                  <FileInput
                    label="Pre-approval letter / POF (proof of funds)"
                    placeholder={getFilesLabel(controllerProps.value)}
                    onFileUpload={file =>
                      onFileUpload(file, 'preapproval_pof', 'Pre approval letter')
                    }
                    onFileRemove={file => onFileRemove(file, 'preapproval_pof')}
                    files={controllerProps.value}
                    accept={FileInputACCEPT.ALL}
                    multiple
                    showSpinner
                    data-cy="upload_pof"
                    {...controllerProps}
                  />
                )}
              />
            </div>
            <Textarea
              ref={register}
              name="additional_info"
              label="Additional Information"
              placeholder="Enter Additional Information"
              className="info-form__textarea"
              data-cy="additional_info"
            />
          </>
        )}

        <div className="info-form__submit-group">
          <Button className="info-form__btn-submit" primary data-cy="continue-button">
            Continue
          </Button>
        </div>
      </form>
    </InfoFormContainer>
  );
};

export default BuyerInfoForm;
